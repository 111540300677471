<template>
	<div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header pa-0 mt-3">
        <h1>My Occupational Accident Insurance</h1>
      </div>
      <div class="dashboard__container--body mb-5 pt-3" style="width:auto;">
      	<div class="dashboard__container--body--col shiftCard">
	      	<div class="mt-3 flex flex-column whiteBack">
					<p class="strong">Your insurance carrier is Great American Insurance Group.</p><p>To report a claim, call <a href="tel:833-444-0161">833-444-0161</a>.</p>
					<p>Your policy number is #OA4504382.</p>
					<div class="mt-3">
						<a href="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/insurance%2F2023%20NB%20-%20OnDemand%20Occ%20Acc%20Loss%20Notice%20App%20Jump%20Staffing.pdf?alt=media&token=86da2c29-6dbe-4ecc-8f96-294a56ba4ee0" target="_blank">
						<button class="btn btn__primary">Claims Form</button>
						</a>
					</div>
					</div>
					
				</div>
				<div class="dashboard__container--body--col">
					<div class="mt-3 flex flex-column">
						<p>In the event of an accident during a gig with Jump, here's what you need to do: </p>
						<p>1 - Seek treatment for medical emergencies<br />Please got to the nearest urgent care or hospital in the event of a medical emergency</p> 
						<p>2 - Contact support at support@jumpstaffing.com to let us know you've been injured and let us know if you have questions about your options and insurance coverage. You will be provided with a form to complete. After the form is submitted, you will receive a call from an insurance agent within 48 hours of submission.</p>
						<!-- <p>Complete the claims form online <a href="https://ondemand.gaig.com" target="_blank">here</a>.</p> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
  name: 'oa'
}
</script>